import React from 'react';
import ProjectPage from '../../components/ProjectPage/ProjectPage';
import SimpleEntryGallery from '../../components/SimpleEntryGallery/SimpleEntryGallery';
import { useJimOCallaghanInfo } from '../../content/projects/jim-ocallaghan.project';
import { PageComponent } from '../../types/gatsby.types';
// import './ProjectPageJimOCallaghan.scss';

type ProjectPageJimOCallaghanProps = {}

const ProjectPageJimOCallaghan: PageComponent<ProjectPageJimOCallaghanProps> = props => {
  const metaInfo = useJimOCallaghanInfo();
  return <ProjectPage
    {...props} // always include
    project={metaInfo}
  >
    <SimpleEntryGallery entry={metaInfo} autoAddMobileSlides />
  </ProjectPage>
}

export default ProjectPageJimOCallaghan;